import React, {useEffect} from 'react'
import * as Styled from '../styles/tour.style.jsx'
import {window, document} from 'browser-monads'
import Helmet from 'react-helmet'

import Footer from '../components/Footer'

const addScript = (url) => {
  const script = document.createElement('script')
  script.src = url
  script.async = true
  document.body.appendChild(script)
}

const Tour = () => {
  useEffect(() => {
    if (typeof window !== undefined) {
      addScript('//widget.songkick.com/8745944/widget.js')
    }
  }, [])

  return (
    <>
      <Styled.Tour>
        <Helmet title="Tour | Jacques" htmlAttributes={{lang: 'fr'}} />
        <a
          href="https://www.songkick.com/artists/8745944"
          class="songkick-widget"
          data-theme="dark"
          data-detect-style="true"
          data-background-color="transparent"
          data-locale="en"
        >
          Jacques tour dates
        </a>
      </Styled.Tour>
      <Footer />
    </>
  )
}

export default Tour
